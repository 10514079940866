import { orderBy } from 'lodash';

const menuItemsByRole = {
  generator: [{ title: 'Gerador', link: '/gerador', order: 1 }],
  temporary_storage: [{ title: 'Armazenador Temporário', link: '/armazenador-temporario', order: 2 }],
  transporter: [{ title: 'Transportador', link: '/transportador', order: 3 }],
  receiver: [{ title: 'Destinador', link: '/destinador', order: 4 }],
};

export function getMenuItems(roles) {
  if (!roles || !Array.isArray(roles)) return [];
  const menu = [];
  roles.forEach((role) => {
    const items = menuItemsByRole[role] || [];
    items.forEach(item => menu.push(item));
  });
  return orderBy(menu, 'order');
}
