import { getMenuItems } from '../../utils/menuItems';

const defaulState = {
  menu: [],
  title: 'MTR',
  breadcrumbs: [],
  stepper: 1,
  snackbar: { show: false, button: { show: true, text: 'Fechar' } },
};

// actions
const actions = {
  currentBreadcrumbs({ commit }, breadcrumbs) {
    commit('currentBreadcrumbs', breadcrumbs);
  },

  changeStep({ commit }, step) {
    commit('changeStep', step);
  },

  loadMenuItems({ commit, rootGetters }) {
    const user = rootGetters['auth/currentUser'];
    const userRoles = _.get(user, 'profiles');
    const menuItems = getMenuItems(userRoles);
    commit('setMenuItem', menuItems);
  },

  toggleSnackbar({ commit }, payload) {
    commit('TOGGLE_SNACKBAR', payload);
  },
};

// getters
const getters = {
  getTitle: state => state.title,
  getMenuItems: state => state.menu,
  breadcrumbs: state => state.breadcrumbs,
  stepper: state => state.stepper,
  snackbar: state => state.snackbar,
};

// mutations
/* eslint-disable no-param-reassign */
const mutations = {
  currentBreadcrumbs(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs;
  },

  changeStep(state, step) {
    state.stepper = step;
  },

  setMenuItem(state, menuItems) {
    state.menu = menuItems;
  },

  TOGGLE_SNACKBAR(state, payload) {
    state.snackbar = { ...state.snackbar, ...payload };
  },

};

/* eslint-enable no-param-reassign */

export default {
  namespaced: true,
  state: defaulState,
  getters,
  actions,
  mutations,
};
