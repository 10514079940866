import '@babel/polyfill';
import { abilitiesPlugin } from '@casl/vue';
import Vue from 'vue';
import _ from 'lodash';
import axios from 'axios';

import { ability } from './ability';

import './plugins/vuetify';
import './plugins/vue-axios';
import './plugins/vue-jwt';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_URI;


Vue.use(abilitiesPlugin, ability);

Object.defineProperty(Vue.prototype, '_', { value: _ });

// Setup vue-auth
/* eslint-disable global-require */
// Vue.use(require('@websanova/vue-auth'), {
//   auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
//   http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
//   router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
//   parseUserData(data) {
//     store.commit('auth/setCurrentUser', data);
//   },
// });
/* eslint-enable global-require */

axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response.status === 401) {
      const localStorageItems = ['token', 'tokenData', 'access_token', 'user']
      localStorageItems.forEach((item) => {
        localStorage.setItem(item, '');
      });

      router.push('/login');
      store.dispatch('auth/setCurrentUser', {});
      store.dispatch('mtr/setDefaultState');
    }
    return Promise.reject(error);
  },
);

// Load capabilities -> changed to initialiseStore
// router.beforeResolve((to, from, next) => {
// const token = localStorage.getItem('token');
// debugger
// if (token === undefined) {
//   next();
// }
// const tokenData = app.$jwt.decode(token);
// app.$ability.update(tokenData.capabilities);
// next();
// });

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
