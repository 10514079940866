<template>
  <header class="mtr-brand">
    <h1 class="title">{{title}}</h1>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MtrBrand',
  computed: {
    ...mapGetters({
      title: 'application/getTitle',
    }),
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/_variables";

.mtr-brand {
  & {
    padding: 1rem 2rem;
  }

  > .title {
    margin: 0;
    text-transform: uppercase;
    line-height: 1;
    color: #fff;
  }
}
</style>
