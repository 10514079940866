const defaultState = {
  errorMessage: '',
};

// actions
const actions = {
  setErrorMessage({ commit }, message) {
    commit('setErrorMessage', message);
  },
};

// getters
const getters = {
  errorMessage: state => state.errorMessage,
};

// mutations
const mutations = {
  setErrorMessage: (state, message) => {
    state.errorMessage = message;
  },
};

export default {
  state: defaultState,
  actions,
  getters,
  mutations,
  namespaced: true,
};
