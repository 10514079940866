<template>
  <v-dialog
    width="450"
    v-model="isOfflineVisible"
    persistent
  >
    <v-card>
      <v-card-title class="headline green lighter-4 white--text" primary-title>
      Sistema temporariamente indisponível
      </v-card-title>

      <v-card-text>
        <div
            class="ml-3 mb-2 subheading font-weight-bold"
        >
            Estamos na janela de backup automático dos dados. O sistema estará disponível a partir das 6 horas da manhã.
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'OfflineDialog',
  computed: {
    isOfflineVisible() {
      const hour = new Date().getUTCHours() - 3;
      return hour >= 0 && hour < 6;
    },
  }
};
</script>
