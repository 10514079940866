import authService from '@/services/auth-service';
import userService from '@/services/user-service';
import _ from 'lodash';
import { ability } from '@/ability';

const authState = {
  currentUser: {
    avatar: '',
  },
  emailConfirmed: false,
  person: {},
};

// mutations
const mutations = {
  initialiseStore: (state) => {
    //set capability and load user informations to vuex store
    const tokenData = localStorage.getItem('tokenData');

    if (tokenData !== null) {
      const userInfo = JSON.parse(localStorage.getItem('tokenData'))
      ability.update(userInfo.rules);
      _.set(state, 'currentUser', userInfo);
    }
  },

  confirmEmail: (state, confirmed) => {
    state.emailConfirmed = confirmed;
  },

  setCurrentUser: (state, user) => {
    _.set(state, 'currentUser', user);
  },

  setLicenses: (state, licenses) => {
    _.set(state, 'currentUser.person.licenses', licenses);
  },

  setOffice: (state, office) => {
    const user = state.currentUser;
    if (!user.person) return;
    user.person.offices.push(office);
  },

  updateOffice: (state, office) => {
    const user = state.currentUser;
    if (!user.person) return;
    user.person.offices = user.person.offices.map((o) => {
      if (office.id === o.id) {
        return office;
      }
      return o;
    });
    _.set(state, 'currentUser', user);
  },

  deleteOffice: (state, officeId) => {
    const user = state.currentUser;
    if (!user.person) return;
    user.person.offices = user.person.offices.filter((o) => {
      if (officeId === o.id) {
        return false;
      }
      return true;
    });
    _.set(state, 'currentUser', user);
  },

  setPerson: (state, value) => {
    state.currentUser = { ...state.currentUser, person: value };
  },
  setPersonInfo: (state, payload) => {
    state.person = payload;
  },

  updateLicense: (state, newLicenseData) => {
    const { id } = newLicenseData;
    const licenses = _.get(state, 'currentUser.person.licenses', []);
    const licenseToUpdate = _.find(licenses, { id });
    if (!licenseToUpdate) licenses.push(newLicenseData);
    const licenseIndex = licenses.indexOf(licenseToUpdate);
    licenses[licenseIndex] = newLicenseData;
  },

};

// actions
const actions = {
  // registerUser(context, userData) {
  // },

  async loadUserInfo({ commit }) {
    const tokenData = JSON.parse(localStorage.getItem('tokenData'));
    let personInfo = JSON.parse(localStorage.getItem('person'));
    if (personInfo === null && tokenData !== null) {
      personInfo = await authService.getPerson(tokenData.person.id);
      localStorage.setItem('person', JSON.stringify(personInfo));
    }
    commit('setPersonInfo', personInfo);
    commit('initialiseStore');
  },

  login(context, { instance, data }) {
    instance.$auth.login({
      data,
    });
  },

  emailVerification(context, token) {
    return authService.emailVerification(token);
  },

  emailConfirmed({ commit }, value) {
    commit('confirmEmail', value);
  },

  async updatePerson({ commit }, [personId, person]) {
    const updatedPerson = await userService.updatePerson(personId, person);
    const keysToOmit = ['users'];
    const personData = _.omit(updatedPerson, keysToOmit);
    commit('setPerson', personData);
  },

  async createPerson({ commit }, person) {
    const createdPerson = await userService.createPerson(person);
    const keysToOmit = ['users'];
    const personData = _.omit(createdPerson, keysToOmit);
    commit('setPerson', personData);
  },

  async updateOffice({ commit }, [officeId, office]) {
    const createdOffice = await userService.updateOffice(officeId, office);
    const keysToOmit = ['person'];
    const officeData = _.omit(createdOffice, keysToOmit);
    commit('updateOffice', officeData);
  },

  async deleteOffice({ commit }, officeId) {
    await userService.deleteOffice(officeId);
    commit('deleteOffice', officeId);
  },

  async createOffice({ commit }, office) {
    const createdOffice = await userService.createOffice(office);
    const keysToOmit = ['person'];
    const officeData = _.omit(createdOffice, keysToOmit);
    commit('setOffice', officeData);
  },

  async createLicenses({ commit }, licenses) {
    const { createdLicenses } = await userService.createLicenses(licenses);
    commit('setLicenses', createdLicenses);
  },

  async updateLicenses({ commit, getters }, licenses) {
    const personId = _.get(getters, 'currentUser.person.id');
    const { editedLicenses } = await userService.updateLicenses(personId, licenses);
    editedLicenses.forEach((license) => {
      commit('updateLicense', license);
    });
  },

  setPerson({ commit }, value) {
    commit('setPerson', value);
  },

  setCurrentUser({ commit }, value) {
    commit('setCurrentUser', value);
  },

  resendEmailValidationToken(context, token) {
    return authService.resendEmailValidationToken(token);
  },

  recoveryPassword(context, email) {
    return authService.recoveryPassword(email);
  },

  updatePassword(context, { password, token }) {
    return authService.updatePassword(password, token);
  },
};

// getters
const getters = {
  emailConfirmed: state => state.emailConfirmed,
  currentUser: state => state.currentUser,
};

export default {
  state: authState,
  actions,
  getters,
  mutations,
  namespaced: true,
};
