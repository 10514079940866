import Vue from 'vue';
import Router from 'vue-router';
import _ from 'lodash';
import Home from './views/Home.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        auth: true,
      },
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue'),
    },
    {
      path: '/cadastro',
      name: 'register',
      component: () => import('./views/auth/register/Register.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/verifica-mtr',
      name: 'verifica-mtr',
      component: () => import('./views/auth/VerificaMtr.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/cadastro/vinculado',
      name: 'register-user',
      component: () => import('./views/auth/register/User.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/cadastro/entidade',
      name: 'register-entity',
      component: () => import('./views/auth/register/Entity.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/conta-criada',
      name: 'registerSuccess',
      component: () => import('./views/auth/RegisterSuccess.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/auth/Login.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/configuracoes',
      name: 'Configurações',
      component: () => import('./views/settings/Settings.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/perfis-de-acesso',
      name: 'Perfis de acesso',
      component: () => import('./views/settings/AccessProfiles.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/gerenciar-perfil/:id',
      name: 'Gerenciar Perfil',
      component: () => import('./views/settings/ManageProfile.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/colaboradores',
      name: 'Colaboradores',
      component: () => import('./views/settings/Employees.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/confirmar-cadastro/:confirmation_token',
      name: 'emailVerification',
      component: () => import('./views/auth/EmailVerification.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/meus-dados',
      name: 'Meus Dados',
      component: () => import('./views/user/User.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/licencas-pendentes',
      name: 'Licenças Pendentes',
      component: () => import('./views/settings/PendingLicenses.vue'),
      meta: {
        auth: true,
        admin: true,
      },
    },
    {
      path: '/licencas-cadastradas',
      name: 'Licenças Cadastradas',
      component: () => import('./views/settings/RegisteredLicenses.vue'),
      meta: {
        auth: true,
        admin: true,
      },
    },
    {
      path: '/mtrs-cadastrados',
      name: 'MTRs Cadastrados',
      component: () => import('./views/settings/RegisteredMTRs.vue'),
      meta: {
        auth: true,
        admin: true,
      },
    },
    {
      path: '/empresas',
      name: 'Empresas',
      component: () => import('./views/settings/Companies.vue'),
      meta: {
        auth: true,
        admin: true,
      },
    },
    {
      path: '/empresas/:id',
      name: 'Dados da Empresa',
      component: () => import('./views/company/CompanyData.vue'),
      meta: {
        auth: true,
        admin: true,
      },
      props: true,
    },
    {
      path: '/usuarios',
      name: 'Usuários',
      component: () => import('./views/settings/Users.vue'),
      meta: {
        auth: true,
        admin: true,
      },
    },
    {
      path: '/usuarios/:id',
      name: 'Dados do Usuário',
      component: () => import('./views/user/UserData.vue'),
      meta: {
        auth: true,
        admin: true,
      },
    },
    {
      path: '/empresas-pendentes',
      name: 'Empresas Pendentes',
      component: () => import('./views/settings/PendingCompanies.vue'),
      meta: {
        auth: true,
        admin: true,
      },
    },
    {
      path: '/recuperar-senha',
      name: 'Esqueci a Senha',
      component: () => import('./views/auth/RecoveryPassword.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/alterar-senha',
      name: 'Alterar Senha',
      component: () => import('./views/auth/UpdatePassword.vue'),
      // props: route => ({ query: route.query.token }),
      meta: {
        auth: false,
      },
    },
    {
      path: '/destinador',
      name: 'Destinador',
      component: () => import('./views/receiver/Receiver.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/gerador',
      name: 'Gerador',
      component: () => import('./views/generator/Generator.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/armazenador-temporario',
      name: 'Armazenador Temporário',
      component: () => import('./views/temporary_storage/TemporaryStorage.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/receber-mtr/:id',
      name: 'Receber MTR',
      component: () => import('./views/receiver/ReceiveMtr.vue'),
      meta: {
        auth: true,
      },
      props: true,
    },
    {
      path: '/mtr-person-form',
      name: 'Mtr Person Form',
      component: () => import('./components/mtr/MtrPersonForm.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/mtr/cadastrar',
      name: 'Cadastro de MTR',
      component: () => import('./views/mtr/MtrRegister.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/mtr/editar/:id',
      name: 'Editar MTR',
      component: () => import('./views/mtr/MtrRegister.vue'),
      meta: {
        auth: true,
      },
      props: true,
    },
    {
      path: '/transportador',
      name: 'Transportador',
      component: () => import('./views/transporter/Transporter.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/emitir-cdf',
      name: 'Emitir CDF',
      component: () => import('./views/cdf/GenerateCdf.vue'),
      meta: {
        auth: true,
      },
    },
    {
      path: '/:document/:id/ver-pdf',
      name: 'Ver PDF MTR',
      component: () => import('./views/mtr/MtrPdfShow.vue'),
      meta: {
        auth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.admin !== undefined) {
    if (to.meta.admin) {
      const { roles } = JSON.parse(localStorage.getItem('tokenData'));
      if (roles.includes('admin')) {
        next();
      } else {
        next('/gerador');
      }
    } else {
      next('/gerador');
    }
  }

  if (_.isEmpty(localStorage.getItem('token')) && to.meta.auth) {
    next('/login');
  } else {
    next();
  }
});

Vue.router = router;

export default Vue.router;
