import { render, staticRenderFns } from "./NavigationBreadcrumb.vue?vue&type=template&id=4412a116&scoped=true&"
import script from "./NavigationBreadcrumb.vue?vue&type=script&lang=js&"
export * from "./NavigationBreadcrumb.vue?vue&type=script&lang=js&"
import style0 from "./NavigationBreadcrumb.vue?vue&type=style&index=0&id=4412a116&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4412a116",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/SGORS-Front/SGORS-Front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4412a116')) {
      api.createRecord('4412a116', component.options)
    } else {
      api.reload('4412a116', component.options)
    }
    module.hot.accept("./NavigationBreadcrumb.vue?vue&type=template&id=4412a116&scoped=true&", function () {
      api.rerender('4412a116', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/base/NavigationBreadcrumb.vue"
export default component.exports