import axios from 'axios';
import AS from './auth-service';

const authService = {
  login(payload) {
    return axios.post('auth/login', payload);
  },

  async refreshToken() {
    const response = await axios.post('auth/refresh_token', {}, AS.getDefaultHeaders());
    return response.data;
  },

  checkForUnits(cpf) {
    return axios.post('auth/check_for_units', { cpf });
  },

  entityRegister(payload) {
    return axios.post('auth/entity_register', payload);
  },

  entityRegisterForUser(payload) {
    return axios.post('auth/entity_register_for_user', payload);
  },

  linkedRegister(payload) {
    return axios.post('auth/linked_register', payload);
  },

  async getPerson(id) {
    const response = await axios.get(`auth/person/${id}`, AS.getDefaultHeaders());
    return response.data;
  },

  emailVerification(confirmationToken) {
    return axios.post('auth/confirmation', { confirmation_token: confirmationToken });
  },

  resendEmailValidationToken(token) {
    return axios.create('auth/resend-email-proof-token', { token });
  },

  recoveryPassword(email) {
    return axios.post('auth/send_reset_password_instructions', { email });
  },

  updatePassword(new_password, token) {
    return axios.post('auth/reset_password', { new_password, token });
  },
  getDefaultHeaders(additionalHeaders = {}) {
    return {
      headers: {
        ...additionalHeaders,
        Authorization: `${localStorage.getItem('token')}`,
        Accept: 'application/json',
      },
    };
  },
};

export default authService;
