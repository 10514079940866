import authService from '@/services/auth-service';
import userService from '@/services/user-service';
import _ from 'lodash';

const authState = {
  currentUser: {
    avatar: '',
  },
  emailConfirmed: false,
  employees: [],
};

// actions
const actions = {
  // registerUser(context, userData) {
  // },

  emailVerification(context, token) {
    return authService.emailVerification(token);
  },

  emailConfirmed({ commit }, value) {
    commit('confirmEmail', value);
  },

  async createEmployee({ commit }, employee) {
    const createdEmployee = await userService.createEmployee(employee);
    commit('setPerson', createdEmployee);
  },

  setPerson({ commit }, value) {
    commit('setPerson', value);
  },

  setCurrentUser({ commit }, value) {
    commit('setCurrentUser', value);
  },

  resendEmailValidationToken(context, token) {
    return authService.resendEmailValidationToken(token);
  },

  recoveryPassword(context, email) {
    return authService.recoveryPassword(email);
  },

  updatePassword(context, { password, token }) {
    return authService.updatePassword(password, token);
  },
};

// getters
const getters = {
  emailConfirmed: state => state.emailConfirmed,
  currentUser: state => state.currentUser
};

// mutations
const mutations = {
  confirmEmail: (state, confirmed) => {
    state.emailConfirmed = confirmed;
  },

  setCurrentUser: (state, user) => {
    _.set(state, 'currentUser', user);
  },

  setLicenses: (state, licenses) => {
    _.set(state, 'currentUser.person.licenses', licenses);
  },

  setOffice: (state, office) => {
    const user = state.currentUser;
    if (!user.person) return;
    user.person.offices.push(office);
  },

  updateOffice: (state, office) => {
    const user = state.currentUser;
    if (!user.person) return;
    user.person.offices = user.person.offices.map((o) => {
      if (office.id === o.id) {
        return office;
      }
      return o;
    });
    _.set(state, 'currentUser', user);
  },

  deleteOffice: (state, officeId) => {
    const user = state.currentUser;
    if (!user.person) return;
    user.person.offices = user.person.offices.filter((o) => {
      if (officeId === o.id) {
        return false;
      }
      return true;
    });
    _.set(state, 'currentUser', user);
  },


  setPerson: (state, value) => {
    state.currentUser = { ...state.currentUser, person: value };
  },

  updateLicense: (state, newLicenseData) => {
    const { id } = newLicenseData;
    const licenses = _.get(state, 'currentUser.person.licenses', []);
    const licenseToUpdate = _.find(licenses, { id });
    if (!licenseToUpdate) licenses.push(newLicenseData);
    const licenseIndex = licenses.indexOf(licenseToUpdate);
    licenses[licenseIndex] = newLicenseData;
  },
};

export default {
  state: authState,
  actions,
  getters,
  mutations,
  namespaced: true,
};
