var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "in-layout",
    [
      _c(
        "v-container",
        { attrs: { slot: "content", fluid: "" }, slot: "content" },
        [
          _c(
            "v-slide-y-transition",
            { attrs: { mode: "out-in" } },
            [
              _c("v-layout", { attrs: { column: "", "align-center": "" } }, [
                _c("blockquote", [
                  _vm._v(
                    "\n          “First, solve the problem. Then, write the code.”\n          "
                  ),
                  _c("footer", [
                    _c("small", [_c("em", [_vm._v("—John Johnson")])]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }