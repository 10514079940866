var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navigation-breadcrumb" },
    [
      _c(
        "v-breadcrumbs",
        { attrs: { divider: "/" } },
        _vm._l(_vm.items, function (item) {
          return _c(
            "v-breadcrumbs-item",
            { key: item.name, attrs: { href: `#${item.path}` } },
            [_vm._v("\n      " + _vm._s(_vm.capitalize(item.name)) + "\n    ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }