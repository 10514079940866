<template>
  <div class="navigation-breadcrumb">
    <v-breadcrumbs divider="/">
      <v-breadcrumbs-item
        v-for="item in items"
        :key="item.name"
        :href="`#${item.path}`">
        {{ capitalize(item.name) }}
      </v-breadcrumbs-item>
    </v-breadcrumbs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { capitalize } from 'lodash';

export default {
  name: 'NavigationBreadcrumb',
  data() {
    return {
      routes: this.$route.matched,
      capitalize,
    };
  },
  computed: {
    ...mapGetters({
      items: 'application/breadcrumbs',
    }),
  },
  mounted() {
    const breadcrumbs = this.routes.map(route => ({
      name: route.name,
      disabled: true,
    }));

    this.$store.dispatch('application/currentBreadcrumbs', breadcrumbs);
  },
};
</script>

<style scoped lang="scss">
.navigation-breadcrumb {
  > .breadcrumbs {
    padding: 16px 16px 14px;
    margin: auto;
    border-bottom: 1px solid #f2f2f2;
  }
}
</style>
