<template>
  <div class="navigation-header">
    <nav class="navigation">
      <v-btn
        v-for="item in navItems"
        :to="item.link"
        :key="item.title"
        flat
      >
        <span class="text">{{item.title}}</span>
      </v-btn>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'NavigationHeader',
  props: {
    navItems: Array,
  },
};
</script>
