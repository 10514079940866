
import mtrService from '@/services/mtr-service';
import { forEach, findIndex } from 'lodash';

const defaulState = {
  measureUnities: [],
  physicalStates: [],
  packagings: [],
  technologies: [],
  transporterMtrs: [],
  generatorMtrs: [],
  receiverMtrs: [],
  receivedMtrs: [],
  selectedMtr: {},
};

// actions
const actions = {
  async fetchMeasureUnities({ commit }) {
    const units = await mtrService.measureUnitiesList();
    commit('SET_MEASURE_UNITIES', units);
  },

  async fetchPhysicalStates({ commit }) {
    const physicalStates = await mtrService.physicalStatesList();
    commit('SET_PHYSICAL_STATES', physicalStates);
  },

  async fetchPackagings({ commit }) {
    const packagings = await mtrService.packagingsList();
    commit('SET_PACKAGINGS', packagings);
  },

  async fetchTechnologies({ commit }) {
    const disposalTechnologiesList = await mtrService.disposalTechnologiesList();
    commit('SET_TECHNOLOGIES', disposalTechnologiesList);
  },

  async fetchGeneratorMtrs({ commit }, payload) {
    const mtrs = await mtrService.mtrGenerator(payload);
    commit('SET_GENERATOR_MTRS', mtrs);
  },

  async fetchTransporterMtrs({ commit }) {
    const mtrs = await mtrService.mtrTransporter();
    commit('SET_TRANSPORTER_MTRS', mtrs);
  },

  async fetchReceiverMtrs({ commit }) {
    const mtrs = await mtrService.mtrReceiver();
    commit('SET_RECEIVER_MTRS', mtrs);
  },

  setMtr({ commit }, payload) {
    commit('SET_SELECTED_MTR', payload);
  },

  async fetchGeneratorReceivedMtrs({ commit }, {generatorId, receiverId}) {
    const mtrs = await mtrService.mtrsReceivedWithoutCDF(generatorId, receiverId);
    commit('SET_RECEIVED_MTRS', mtrs);
  },

  async receiveMtr({ commit }, payload) {
    return mtrService.receiveMtr(payload);
    // const response = await mtrService.receiveMtr(payload);
    // commit('');
  },

  async rejectMtr({ commit }, payload) {
    // const response = await mtrService.rejectMtr(payload);
    // commit('');
  },

  emptyReceivedMtrs({ commit }) {
    commit('EMPTY_RECEIVED_MTRS');
  },

  setDefaultState({ commit }) {
    // commit('SET_DEFAULT_STATE');
  },
  setMtrStatusOnGeneratorsList({ commit }, payload) {
    commit('SET_MTR_STAUS_ON_GENERATORS_LIST', payload);
  },

};

// getters
const getters = {
  measureUnities: state => state.measureUnities,
  physicalStates: state => state.physicalStates,
  packagings: state => state.packagings,
  technologies: state => state.technologies,
  transporterMtrs: state => state.transporterMtrs,
  generatorMtrs: state => state.generatorMtrs,
  receiverMtrs: state => state.receiverMtrs,
  receivedMtrs: state => state.receivedMtrs,
  selectedMtr: state => state.selectedMtr,
};

// mutations
/* eslint-disable no-param-reassign */
const mutations = {
  SET_MEASURE_UNITIES: (state, measureUnities) => {
    state.measureUnities = measureUnities;
  },

  SET_PHYSICAL_STATES: (state, payload) => {
    state.physicalStates = payload;
  },

  SET_PACKAGINGS: (state, payload) => {
    state.packagings = payload;
  },

  SET_TECHNOLOGIES: (state, payload) => {
    state.technologies = payload;
  },

  SET_TRANSPORTER_MTRS: (state, payload) => {
    state.transporterMtrs = payload;
  },

  SET_GENERATOR_MTRS: (state, payload) => {
    state.generatorMtrs = payload;
  },

  SET_RECEIVER_MTRS: (state, payload) => {
    state.receiverMtrs = payload;
  },

  SET_RECEIVED_MTRS: (state, payload) => {
    state.receivedMtrs = payload;
  },

  SET_SELECTED_MTR: (state, payload) => {
    state.selectedMtr = payload;
  },

  EMPTY_RECEIVED_MTRS: (state) => {
    state.receivedMtrs = [];
  },

  SET_DEFAULT_STATE: (state) => {
    forEach(state, (item, key) => {
      state[key] = [];
    });
  },

  SET_MTR_STAUS_ON_GENERATORS_LIST(state, payload) {
    const index = findIndex(state.generatorMtrs.mtrs, { id: payload.mtrId });
    if (index !== -1) {
      state.generatorMtrs.mtrs[index].status = 'cancelled';
    }
  },
};
/* eslint-enable no-param-reassign */

export default {
  state: defaulState,
  getters,
  actions,
  mutations,
  namespaced: true,
};
