<template>
  <v-app>
    <v-content>
      <router-view></router-view>
      <custom-snackbar />
      <offline-dialog />
    </v-content>
  </v-app>
</template>

<script>
import CustomSnackbar from '@/components/base/Custom-Snackbar.vue';
import OfflineDialog from '@/components/offline/OfflineDialog';

export default {
  name: 'App',
  components: {
    CustomSnackbar,OfflineDialog
  },
  beforeCreate() {
    this.$store.dispatch('auth/loadUserInfo');
  },
};
</script>

<style lang="sass">
@import './scss/style';
</style>

