var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ima-logo",
      on: {
        click: function ($event) {
          return _vm.$router.push("/configuracoes")
        },
      },
    },
    [
      _c("img", {
        staticClass: "image",
        attrs: { src: _vm.logo, alt: "Logo IMA Alagoas" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }