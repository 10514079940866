<template>
  <div class="notification-dropdown">
    <!-- <v-btn fab flat dark small>
      <v-icon>mdi-bell-outline mdi-24px</v-icon>
    </v-btn> -->
  </div>
</template>

<script>
export default {
  name: 'NotificationDropdown',
};
</script>

<style scoped lang="scss">
</style>
