<template>
  <div class="ima-logo" @click="$router.push('/configuracoes')">
    <img class="image" :src="logo" alt="Logo IMA Alagoas">
  </div>
</template>

<script>

export default {
  name: 'ImaLogo',
  props: ['type'],
  computed: {
    logo() {
      const type = this.type || 'light';
      return require(`@/assets/ima-logo-${type}.svg`); // eslint-disable-line
    },
  },
};
</script>

<style scoped lang="scss">
.ima-logo {
  cursor: pointer;
  > .image {
    width: 80px;
  }
}
</style>
