import personService from '@/services/person-service';
import mtrModelService from '@/services/mtr-model-service';
import { remove } from 'lodash';

const defaulState = {
  person: {
    cpf_cnpj: '00000000000',
    logo: { url: null },
  },
  offices: [],
  employees: [],
  mtr_models: [],
  mtr_model: {},
};

// getters
const getters = {
  offices: state => state.offices,
  person: state => state.person,
  employees: state => state.employees,
  mtr_models: state => state.mtr_models,
};

// mutations
/* eslint-disable no-param-reassign */
const mutations = {
  SET_PERSON: (state, payload) => {
    state.person = payload;
  },
  SET_OFFICES: (state, payload) => {
    state.offices = payload;
  },
  SET_EMPLOYEES: (state, payload) => {
    state.employees = payload;
  },
  REMOVE_OFFICE: (state, personId) => {
    remove(state.offices, office => office.id === personId);
  },

  SET_MTR_MODELS: (state, mtrModels) => {
    state.mtr_models = mtrModels;
  },

  SET_MTR_MODEL: (state, mtrModel) => {
    state.mtr_model = mtrModel;
  },

  SET_LOGO: (state, url) => {
    state.person.logo.url = url;
  },
};
/* eslint-enable no-param-reassign */

// actions
const actions = {
  async setOffices({ commit }) {
    const offices = await personService.offices();
    commit('SET_OFFICES', offices);
  },
  async fetchPerson({ commit }, personId) {
    const person = await personService.show(personId);
    commit('SET_PERSON', person);
  },
  async fetchEmployees({ commit }, personId) {
    const employees = await personService.getEmployees(personId);
    commit('SET_EMPLOYEES', employees);
  },
  async removeOffice({ commit }, payload) {
    await personService.unlinkEmployee(payload);
    commit('REMOVE_OFFICE', payload.person_id);
  },

  setLogo({ commit }, url) {
    commit('SET_LOGO', url);
  },

  async fetchMtrModels({ commit }) {
    const response = await mtrModelService.all();
    commit('SET_MTR_MODELS', response.mtr_models);
  },

  async fetchMtrModel({ commit }, mtrModelId) {

  },

};


export default {
  state: defaulState,
  getters,
  actions,
  mutations,
  namespaced: true,
};
