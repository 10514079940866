var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "navigation-header" }, [
    _c(
      "nav",
      { staticClass: "navigation" },
      _vm._l(_vm.navItems, function (item) {
        return _c(
          "v-btn",
          { key: item.title, attrs: { to: item.link, flat: "" } },
          [_c("span", { staticClass: "text" }, [_vm._v(_vm._s(item.title))])]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }