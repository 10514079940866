import axios from "axios";
import { omit } from "lodash";
import { IS_CPF_CNPJ } from "../utils/regex";
import AS from "./auth-service";

const removeMask = cpfCnpj => cpfCnpj.replace(/\D/g, "");

const mtrService = {
  baseUrl: process.env.VUE_APP_API_URI,

  async create(payload) {
    return axios.post("mtr", payload, AS.getDefaultHeaders());
  },

  update(payload) {
    return axios.post("mtr", payload, AS.getDefaultHeaders());
  },

  destroy(payload) {
    return axios.post("mtr", payload, AS.getDefaultHeaders());
  },

  mtrGenerator(q, page, status, sortBy) {
    if (IS_CPF_CNPJ.test(q)) {
      q = removeMask(q);
    }
    const params = {
      q,
      page,
      status,
      sort_by: sortBy
    };
    return axios.get("mtr/generators/list", {
      params,
      ...AS.getDefaultHeaders()
    });
  },

  mtrGeneratorAdvancedFilter(payload) {
    if (IS_CPF_CNPJ.test(payload.transporter)) {
      payload.transporter = removeMask(payload.transporter);
    }
    if (IS_CPF_CNPJ.test(payload.receiver)) {
      payload.receiver = removeMask(payload.receiver);
    }

    return axios.post(
      "mtr/generators/advanced_filter",
      payload,
      AS.getDefaultHeaders()
    );
  },

  mtrGeneratorExport(payload) {
    return axios.post("mtr/generators/export_mtrs", payload, {
      ...AS.getDefaultHeaders(),
      responseType: "blob"
    });
  },

  async mtrsReceivedWithoutCDF(generatorId, receiverId) {
    const url = `mtr/generators/${generatorId}/pending_cdf?receiver_id=${receiverId}`;

    const response = await axios.get(url, AS.getDefaultHeaders());
    return response.data;
  },

  mtrTransporter(q, page, status, sortBy) {
    if (IS_CPF_CNPJ.test(q)) {
      q = removeMask(q);
    }
    const params = {
      q,
      page,
      status,
      sort_by: sortBy
    };
    return axios.get("mtr/transporters/list", {
      params,
      ...AS.getDefaultHeaders()
    });
  },

  mtrReceiver(q, page, status, sortBy) {
    if (IS_CPF_CNPJ.test(q)) {
      q = removeMask(q);
    }
    const params = {
      q,
      page,
      status,
      sort_by: sortBy
    };
    return axios.get("mtr/receivers/list", {
      params,
      ...AS.getDefaultHeaders()
    });
  },

  cancelMtr(mtrId, cancelReason) {
    return axios.patch(
      `mtr/${mtrId}/cancel`,
      { cancel_reason: cancelReason },
      AS.getDefaultHeaders()
    );
  },

  mtrTemporaryStorage(q, page, status, sortBy) {
    if (IS_CPF_CNPJ.test(q)) {
      q = removeMask(q);
    }
    const params = {
      q,
      page,
      status,
      sort_by: sortBy
    };
    return axios.get("mtr/temporary_storages/list", {
      params,
      ...AS.getDefaultHeaders()
    });
  },

  async show(mtrId) {
    const response = await axios.get(`mtr/${mtrId}/show`, {
      ...AS.getDefaultHeaders()
    });
    return response;
  },

  async mtrReport(mtrId) {
    const response = await axios.get(`mtr/${mtrId}/download`, {
      ...AS.getDefaultHeaders(),
      responseType: "blob"
    });
    return response.data;
  },

  async mtrContract(mtrId) {
    const response = await axios.get(`mtr/${mtrId}/download_contract`, {
      ...AS.getDefaultHeaders(),
      responseType: "blob"
    });
    return response.data;
  },

  async mtrReceivementReport(mtrId) {
    const response = await axios.get(`mtr/${mtrId}/receipt_report`, {
      ...AS.getDefaultHeaders(),
      responseType: "blob"
    });
    return response.data;
  },

  async mtrCdfReport(cdfId) {
    const response = await axios.get(`cdf/${cdfId}/show.pdf`, {
      ...AS.getDefaultHeaders(),
      responseType: "blob"
    });
    return response.data;
  },

  async mtrCdfShow(cdfId) {
    const response = await axios.get(`cdf/${cdfId}/show`, {
      ...AS.getDefaultHeaders(),
      responseType: "json"
    });
    return response.data;
  },

  async receiveMtr(payload) {
    const url = `${this.baseUrl}/mtr/${payload.id}/receive`;
    const response = await axios.post(
      url,
      omit(payload, ["id"]),
      AS.getDefaultHeaders()
    );
    return response.data;
  },

  async adjustMtrResidue(mtrId, mtrResidueId, payload) {
    const response = await axios.post(
      `mtr/${mtrId}/mtr_residues/${mtrResidueId}/create_adjust_mtr_residue`,
      payload,
      AS.getDefaultHeaders()
    );
    return response.data;
  },

  async cancelAdjustedMtrResidueItem(mtrId, mtrResidueId) {
    const response = await axios.delete(
      `mtr/${mtrId}/mtr_residues/${mtrResidueId}`,
      AS.getDefaultHeaders()
    );
    return response.data;
  },

  async receive(mtrId, mtrResidueId) {
    const response = await axios.post(
      `mtr/${mtrId}/mtr_residues/${mtrResidueId}/receive`,
      {},
      AS.getDefaultHeaders()
    );
    return response.data;
  },

  async cancelReceivement(mtrId, mtrResidueId) {
    const response = await axios.post(
      `mtr/${mtrId}/mtr_residues/${mtrResidueId}/cancel_receivement`,
      {},
      AS.getDefaultHeaders()
    );
    return response.data;
  },

  async rejectMtr({ mtrId, refusalReason }) {
    return axios.patch(
      `mtr/${mtrId}/reject`,
      { refusal_reason: refusalReason },
      AS.getDefaultHeaders()
    );
  },

  async disposalTechnologiesList() {
    const url = "mtr/disposal_technologies";
    const response = await axios.get(url, AS.getDefaultHeaders());
    return response.data;
  },

  async physicalStatesList() {
    const url = "mtr/physical_states";
    const response = await axios.get(url, AS.getDefaultHeaders());
    return response.data;
  },

  async measureUnitiesList() {
    const url = "mtr/measure_units";
    const response = await axios.get(url, AS.getDefaultHeaders());
    return response.data;
  },

  async packagingsList() {
    const url = "mtr/packagings";
    const response = await axios.get(url, AS.getDefaultHeaders());
    return response.data;
  },

  emailVerification(token) {
    return axios.create("auth/login", { token });
  },

  residuesList(q) {
    return axios.get(`mtr/residues?q=${q}`, AS.getDefaultHeaders());
  },

  qrcodePreview(mtrNumber, gTokenResponse) {
    return axios.get(
      `qr_code/preview?mtr_number=${mtrNumber}&g_recaptcha_response=${gTokenResponse}`
    );
  },

  getMtrs({
    mtr = "",
    generator_data = "",
    transporter_data = "",
    receiver_data = "",
    temporary_storage_data = "",
    tipo = "",
    classe = "",
    estado = "",
    um = "",
    page = 1,
    limit = 20,
    status,
    emittedStart,
    emittedEnd,
    sortBy,
    sortDirection
  }) {
    const params = {
      mtr,
      generator_data,
      transporter_data,
      receiver_data,
      temporary_storage_data,
      tipo,
      classe,
      estado,
      um,// Unidade de Medida
      page,
      limit,
      status,
      initial_emission: emittedStart,
      final_emission: emittedEnd,
      sort_by: sortBy,
      sort_direction: sortDirection
    };
    return axios.get("mtr", {
      params,
      headers: AS.getDefaultHeaders().headers
    });
  },

  export({
            mtr = "",
            generator_data = "",
            transporter_data = "",
            receiver_data = "",
            temporary_storage_data = "",
            tipo = "",
            classe = "",
            estado = "",
            um = "",
            status,
            emittedStart,
            emittedEnd,
            sortBy,
            sortDirection
          }) {
    const params = {
      mtr,
      generator_data,
      transporter_data,
      receiver_data,
      temporary_storage_data,
      tipo,
      classe,
      estado,
      um,// Unidade de Medida
      status,
      initial_emission: emittedStart,
      final_emission: emittedEnd,
      sort_by: sortBy,
      sort_direction: sortDirection
    };
    return axios.post("mtr/export", params, {
      ...AS.getDefaultHeaders(),
      responseType: "blob"
    });
  },

  getPendingMtrs() {
    return axios.get("mtr/pending", AS.getDefaultHeaders());
  },

  async getClasses(){
    return axios.get("mtr/classes", AS.getDefaultHeaders());
  }
};

export default mtrService;
