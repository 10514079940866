import axios from 'axios';
import AS from './auth-service';

const userService = {
  baseUrl: process.env.VUE_APP_API_URI,

  async getUsers({ page = 1, limit = 20, q }) {
    return await axios.get(`users/list?page=${page}&limit=${limit}&q=${q}`, AS.getDefaultHeaders());
  },
  async show(userId) {
    const response = await axios.get(`users/${userId}`, AS.getDefaultHeaders());
    return response.data;
  },
  updateUser(userId, data) {
    return axios.patch(`users/${userId}`, data, AS.getDefaultHeaders());
  },
  async createPerson(data) {
    const response = await axios.post('api/v1/people', { person: data });
    return response.data;
  },

  async updatePerson(personId, data) {
    const response = await axios.patch(`/api/v1/people/${personId}`, data);
    return response.data;
  },

  async createOffice(data) {
    const url = `${this.baseUrl}offices`;
    const response = await axios.post(url, data);
    return response.data;
  },

  async createEmployee(data) {
    const url = `${this.baseUrl}people/employees`;
    const response = await axios.post(url, data, AS.getDefaultHeaders());
    return response.data;
  },

  updateEmployee(payload) {
    const url = `${this.baseUrl}people/employees/${payload.id}`;
    return axios.put(url, payload, AS.getDefaultHeaders());
  },

  async uploadLicense(licenseId, file, onUploadProgress) {
    const url = `licenses/${licenseId}/upload_license`;
    const bodyFormData = new FormData();
    const requestOptions = {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
    };
    bodyFormData.append('file', file);
    const response = await axios
      .post(
        url,
        bodyFormData,
        {
          ...AS.getDefaultHeaders(),
          requestOptions,
        },
      );
    return response.data;
  },

  async uploadLogo(file, personId, onUploadProgress) {
    const url = `people/${personId}/upload_logo`;
    const bodyFormData = new FormData();
    const requestOptions = {
      ...AS.getDefaultHeaders({ 'Content-Type': 'multipart/form-data' }),
      onUploadProgress,
    };
    bodyFormData.append('file', file);
    const response = await axios.post(url, bodyFormData, requestOptions);
    return response.data;
  },

  destroyLogo(personId) {
    return axios.delete(`people/${personId}/destroy_logo`, AS.getDefaultHeaders());
  },

  async createLicenses(data) {
    const url = `${this.baseUrl}/licensesBatch`;
    const response = await axios.post(url, data);
    return response.data;
  },

  async updateLicenses(personId, data) {
    const url = `${this.baseUrl}/people/${personId}/licenses`;
    const response = await axios.patch(url, data);
    return response.data;
  },

  async getRoles() {
    const response = await axios.get('users/roles', AS.getDefaultHeaders());
    return response.data;
  },

  updatePassword(password) {
    return axios.put('users/update_password', { password }, AS.getDefaultHeaders());
  },
};

export default userService;
