import cdfService from '@/services/cdf-service';

const defaulState = {
  cdfs: [],
  selectedCdf: [],
  generatorCdfs: [],
  transporterCdfs: [],
  receiverCdfs: [],
};

// actions
const actions = {
  createCdf({ commit }, payload) {
    return cdfService.createCdf(payload);
  },
  async fetchCdfs({ commit }) {
    const cdfs = await apiService.find('cdfs?perPage=-1');
    commit('SET_CDFS', cdfs);
  },

  async fetchGeneratorCdfs({ commit }) {
    const cdfs = await cdfService.cdfGenerator();
    commit('SET_GENERATOR_CDFS', cdfs);
  },

  async fetchReceiverCdfs({ commit }) {
    const cdfs = await cdfService.cdfReceiver();
    commit('SET_RECEIVER_CDFS', cdfs);
  },
};

// getters
const getters = {
  cdfs: state => state.cdfs,
  generatorCdfs: state => state.generatorCdfs,
  transporterCdfs: state => state.transporterCdfs,
  receiverCdfs: state => state.receiverCdfs,
  selectedCdf: state => state.selectedCdf,
};

// mutations
/* eslint-disable no-param-reassign */
const mutations = {
  SET_GENERATOR_CDFS: (state, payload) => {
    state.generatorCdfs = payload;
  },

  SET_TRANSPORTER_CDFS: (state, payload) => {
    state.transporterCdfs = payload;
  },

  SET_RECEIVER_CDFS: (state, payload) => {
    state.receiverCdfs = payload;
  },

  SET_SELECTED_CDF: (state, payload) => {
    state.selectedCdf = payload;
  },

  SET_CDFS: (state, payload) => {
    state.cdfs = payload;
  },
};
/* eslint-enable no-param-reassign */

export default {
  state: defaulState,
  getters,
  actions,
  mutations,
  namespaced: true,
};
