import axios from 'axios';
import AS from './auth-service';

const personService = {

  async search({ profile, q }) {
    const response = await axios.post(
      `people/search/${profile}/`,
      { q },
      AS.getDefaultHeaders(),
    );
    return response;
  },

  async searchGenerator({ q }) {
    return axios.post('people/search_generator/', { q }, AS.getDefaultHeaders());
  },

  async searchForLink({ q }) {
    const response = await axios.post(
      'people/search_for_link',
      { q },
      AS.getDefaultHeaders(),
    );
    return response;
  },

  async show(personId) {
    const response = await axios.get(`people/${personId}/`, AS.getDefaultHeaders());
    return response.data;
  },

  createPerson(data) {
    return axios.post('people', data);
  },

  updatePerson(personId, data) {
    return axios.patch(`people/${personId}`, data, AS.getDefaultHeaders());
  },

  getCompanies({ page = 1, limit = 20, q }) {
    return axios.get(`people/companies/list?page=${page}&limit=${limit}&q=${q}`, AS.getDefaultHeaders());
  },

  getPendingCompanies() {
    return axios.get('people/companies/pending', AS.getDefaultHeaders());
  },

  acceptCompany(entityId) {
    return axios.patch(`auth/entity/${entityId}/approval`, { status: 'approved' }, AS.getDefaultHeaders());
  },

  refuseCompany(entityId, reason) {
    return axios.patch(`auth/entity/${entityId}/approval`, { status: 'not_approved', reason }, AS.getDefaultHeaders());
  },

  async getEmployees(personId) {
    const url = `people/${personId}/employees`;
    const response = await axios.get(url, AS.getDefaultHeaders());
    return response.data;
  },

  checkUserAlreadyExist(cpf) {
    const url = 'people/employees/check_for_existent_user';
    return axios.post(url, { cpf }, AS.getDefaultHeaders());
  },

  async offices() {
    const response = await axios.get('people/offices', AS.getDefaultHeaders());
    return response.data;
  },


  // async updateOffice(officeId, data) {
  //   const url = `${this.baseUrl}/offices/${officeId}`;
  //   const response = await axios.patch(url, data);
  //   return response.data;
  // },
  //
  // async deleteOffice(officeId) {
  //   const url = `${this.baseUrl}/offices/${officeId}`;
  //   const response = await axios.delete(url);
  //   return response.data;
  // },

};

export default personService;
