import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import error from './modules/error';
import mtr from './modules/mtr';
import cdf from './modules/cdf';
import employee from './modules/employee';
import person from './modules/person';

import applicationModule from './modules/application';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    cdf,
    employee,
    error,
    mtr,
    person,
    application: applicationModule,
  },
  strict: debug,
});
