<template>
<in-layout>
  <v-container fluid slot="content">
    <v-slide-y-transition mode="out-in">
      <v-layout column align-center>

        <blockquote>
          &#8220;First, solve the problem. Then, write the code.&#8221;
          <footer>
            <small>
              <em>&mdash;John Johnson</em>
            </small>
          </footer>
        </blockquote>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</in-layout>
</template>

<script>
import InLayout from '@/layouts/InLayout.vue';

export default {
  components: {
    InLayout,
  },
  beforeMount() {
    // if (localStorage.getItem('token') === null) {
    //   this.$router.push('/login');
    //   // TODO remover isso daqui
    // }
  },
  mounted() {
    const { profiles } = this.user;
    if (profiles.indexOf('generator') !== -1) {
      this.$router.push('/gerador');
    } else if (profiles.indexOf('transporter') !== -1) {
      this.$router.push('/transportador');
    } else if (profiles.indexOf('receiver') !== -1) {
      this.$router.push('/destinador');
    }
  },
  computed: {
    user() {
      const user = this.$store.getters['auth/currentUser'];
      return user;
    },
  },
};
</script>


<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
