import axios from 'axios';
import AS from './auth-service';

const mtrModelService = {

  async all() {
    const response = await axios.get('people/mtr_models', AS.getDefaultHeaders());
    return response.data;
  },

  create(payload) {
    return axios.post('people/mtr_models', payload, AS.getDefaultHeaders());
  },

  update(payload) {
    return axios.put(`people/mtr_models/${payload.id}`, payload, AS.getDefaultHeaders());
  },

  destroy(id) {
    return axios.delete(`people/mtr_models/${id}`, AS.getDefaultHeaders());
  },

};

export default mtrModelService;
