var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "account-dropdown" },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: { slot: "activator", icon: "" },
                        slot: "activator",
                      },
                      on
                    ),
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: { name: "Configurações" }, tag: "span" },
                        },
                        [
                          _c("v-icon", { attrs: { color: "grey darken-2" } }, [
                            _vm._v("mdi-settings"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Configurações")])]
      ),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: { slot: "activator", icon: "" },
                        slot: "activator",
                      },
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { color: "grey darken-2" },
                          on: {
                            click: function ($event) {
                              return _vm.logout()
                            },
                          },
                        },
                        [_vm._v("mdi-logout\n        ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Sair")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }