<template>
  <div class="account-dropdown">

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon slot="activator"
        >
          <router-link :to="{ name: 'Configurações' }" tag="span">
            <v-icon color="grey darken-2">mdi-settings</v-icon>
          </router-link>
        </v-btn>
      </template>
      <span>Configurações</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon slot="activator">
          <v-icon color="grey darken-2"
                  @click="logout()">mdi-logout
          </v-icon>
        </v-btn>
      </template>
      <span>Sair</span>
    </v-tooltip>

  </div>
</template>

<script>

export default {
  name: 'AccountDropdown',
  computed: {
    currentUser() {
      const user = this.$store.getters['auth/currentUser'];
      return user;
    },
  },
  methods: {
    logout() {
      localStorage.setItem('token', '');
      this.$router.push('/login');
      this.$store.dispatch('auth/setCurrentUser', {});
      this.$store.dispatch('mtr/setDefaultState');
    },
  },
};
</script>

<style scoped lang="scss">
.link {
  cursor: pointer;
  text-decoration: none;
}
</style>
