<template>
  <v-snackbar
    :value="snackbar.show"
    @input="showSnackbar"
    :color="snackbar.color"
    :multi-line="true"
    :timeout="snackbar.timeout"
  >
    <v-icon
      left
      color="white"
      class="mr-3"
      v-show="snackbar.messageIcon"
    >
    {{ snackbar.messageIcon }}
    </v-icon>
    {{ snackbar.message }}
    <v-btn
      dark
      flat
      @click="!snackbar.button.cb ? toggleSnackbar() : snackbar.button.cb"
      v-show="snackbar.button.show"
    >
      {{ snackbar.button.text }}
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'custom-snackbar',
  computed: {
    ...mapGetters({
      snackbar: 'application/snackbar',
    }),
  },
  methods: {
    toggleSnackbar() {
      this.$store.dispatch('application/toggleSnackbar', { show: false });
    },
    showSnackbar(value) {
      if (!value) {
        this.toggleSnackbar();
      }
    },
  },

};
</script>
