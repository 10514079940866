var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "450", persistent: "" },
      model: {
        value: _vm.isOfflineVisible,
        callback: function ($$v) {
          _vm.isOfflineVisible = $$v
        },
        expression: "isOfflineVisible",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "headline green lighter-4 white--text",
              attrs: { "primary-title": "" },
            },
            [_vm._v("\n    Sistema temporariamente indisponível\n    ")]
          ),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "ml-3 mb-2 subheading font-weight-bold" },
              [
                _vm._v(
                  "\n          Estamos na janela de backup automático dos dados. O sistema estará disponível a partir das 6 horas da manhã.\n      "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }