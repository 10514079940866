import axios from 'axios';

import AS from './auth-service';

const cdfService = {
  async cdfGenerator(page) {
    const response = await axios.get(`cdf/generators/list?page=${page}`, AS.getDefaultHeaders());
    return response.data;
  },
  async cdfReceiver(page) {
    const response = await axios.get(`cdf/receivers/list?page=${page}`, AS.getDefaultHeaders());
    return response.data;
  },
  async createCdf(payload) {
    const response = await axios.post('cdf', payload, AS.getDefaultHeaders());
    return response.data;
  },
};

export default cdfService;
