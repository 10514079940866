var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "in-layout" },
    [
      _c(
        "v-toolbar",
        { staticClass: "header", attrs: { color: "grey lighten-3", flat: "" } },
        [
          _c(
            "v-container",
            { staticClass: "py-3", attrs: { fluid: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("ima-logo"),
                  _c("v-spacer"),
                  _vm.personApproved
                    ? _c("navigation-header", {
                        attrs: { navItems: _vm.navItems },
                      })
                    : _vm._e(),
                  _c("notification-dropdown"),
                  _c("account-dropdown"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "area" },
        [_vm._t("content"), _vm._t("list")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }